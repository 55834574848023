import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs'

const currentLocale = (navigator.language).toLowerCase()
// console.log(currentLocale)
const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: currentLocale,
  fallbackLocale: 'cn', // set fallback locale
  globalInjection: true,
  messages: {
    'zh-cn': require('./cn'),
    'en-us': require('./en')
  }
})
export default i18n
