import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 外部css
import './assets/vendor/bootstrap/css/bootstrap.css'
import './assets/vendor/bootstrap-icons/bootstrap-icons.css'
import './assets/vendor/boxicons/css/boxicons.css'
import './assets/vendor/glightbox/css/glightbox.css'
import './assets/vendor/remixicon/remixicon.css'
import './assets/vendor/swiper/swiper-bundle.min.css'
import './assets/vendor/aos/aos.css'

// 自定义css(最后引入！！！！！！！）
import './assets/css/style.css'

// 外部js
// import AOS from './assets/vendor/aos/aos.js'
import './assets/vendor/bootstrap/js/bootstrap.bundle.min.js'
// import './assets/vendor/isotope-layout/isotope.pkgd.min.js'
import './assets/vendor/php-email-form/validate.js'
import './assets/vendor/swiper/swiper-bundle.min.js'
import './assets/vendor/waypoints/noframework.waypoints.js'

import i18n from '@/common/lang/i18n'
// app.use(store).use(router).use(i18n).mount('#app')
createApp(App).use(store).use(router).use(i18n).mount('#app')
