<template>
  <div>
    <!-- ======= Portfolio Section ======= -->
    <section id="product" class="portfolio section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>{{$t('product.productTitle')}}</h2>
          <p>{{$t('product.productDescription')}}</p>
        </div>

        <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-img"><img src="../assets/img/matong.jpg" class="img-fluid" alt=""></div>
            <div class="product-info text-center">
              <p>{{$t('product.toilet')}}</p>
            </div>

          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <div class="portfolio-img"><img src="../assets/img/yushigui.jpg" class="img-fluid" alt=""></div>
            <div class="product-info text-center">
              <p>{{$t('product.cabinet')}}</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-img"><img src="../assets/img/shuicao.jpg" class="img-fluid" alt=""></div>
            <div class="product-info text-center">
              <p>{{$t('product.sink')}}</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card">
            <div class="portfolio-img"><img src="../assets/img/chufang.jpg" class="img-fluid" alt=""></div>
            <div class="product-info text-center">
              <p>{{$t('product.kitchenFaucet')}}</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <div class="portfolio-img"><img src="../assets/img/mianpen.jpg" class="img-fluid" alt=""></div>
            <div class="product-info text-center">
              <p>{{$t('product.basinFaucet')}}</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-img"><img src="../assets/img/yugang.jpg" class="img-fluid" alt=""></div>
            <div class="product-info text-center">
              <p>{{$t('product.bathFaucet')}}</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card">
            <div class="portfolio-img"><img src="../assets/img/mianpen2.jpg" class="img-fluid" alt=""></div>
            <div class="product-info text-center">
              <p>{{$t('product.highBasinFaucet')}}</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card">
            <div class="portfolio-img"><img src="../assets/img/linyu.jpg" class="img-fluid" alt=""></div>
            <div class="product-info text-center">
              <p>{{$t('product.showerFaucet')}}</p>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <div class="portfolio-img"><img src="../assets/img/yugang2.jpg" class="img-fluid" alt=""></div>
            <div class="product-info text-center">
              <p>{{$t('product.bathtub')}}</p>
            </div>
          </div>

        </div>

      </div>
<!--      <div class="section-title">
        <a href="/product" class="btn-detail">查看更多</a>
      </div>-->
    </section>

  </div>

</template>

<script>
export default {
  name: 'HsProduct'
}
</script>

<style scoped>

</style>
