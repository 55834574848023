<template>
  <div>

    <header id="header" class="fixed-top " ref="header">
      <div class="container d-flex align-items-center">

        <!--        <h1 class="logo me-auto"><a href="/">{{ $t('company.title') }}</a></h1>-->
        <h1 class="logo me-auto"><img src="@/assets/img/logo_laodasi.png"/></h1>
        <nav id="navbar" class="navbar" ref="navbar" >
          <ul @click="closeMenu" >
<!--            <li  v-for="item in menu" :key="item.id">-->
<!--&lt;!&ndash;              <router-link :to="item.href">{{item.name}}</router-link>&ndash;&gt;-->
<!--              <a :class="item.style" :href="item.href" :target="item.target">{{item.name}}</a>-->
<!--            </li>-->
            <li><a class="nav-link scrollto active" href="/home">{{$t('menu.home')}}</a></li>
            <li><a @click.prevent="navClick('/home/#about')" class="nav-link scrollto" href="/home/#about">{{$t('menu.about')}}</a></li>
            <li><a @click.prevent="navClick('/home/#product')" class="nav-link scrollto" href="/home/#product">{{$t('menu.product')}}</a></li>
            <li><a @click.prevent="navClick('/home/#contact')" class="nav-link scrollto" href="/home/#contact">{{$t('menu.contact')}}</a></li>
            <li><a class="nav-link scrollto" href="https://laodasi.tmall.com/index.htm?spm=a1z10.5-b.w5001-22953669660.7.511876277oiAPp&scene=taobao_shop" target="_blank">{{$t('menu.tmall')}}</a></li>
            <li><a class="nav-link scrollto" href="https://mall.jd.com/index-728612.html?from=pc" target="_blank">{{$t('menu.JD')}}</a></li>
            <li><a class="nav-link" href="javascript:void(0)" @click="changeToChinese"><img src="@/assets/img/chinese.png" /></a></li>
            <li><a class="nav-link" href="javascript:void(0)" @click="changeToEnglish"><img src="@/assets/img/english.png" /></a></li>
          </ul>
          <i class="bi mobile-nav-toggle bi-list" ref="menuButton"> </i>
        </nav>

      </div>
    </header><!-- End Header -->
  </div>

</template>

<script>

import { ref, onMounted, computed, nextTick, watch, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useI18n } from 'vue-i18n'


export default {
  name: 'HsNav',
  setup () {
    const { t } = useI18n() // call `useI18n`, and spread `t` from  `useI18n` returning

    let { proxy } = getCurrentInstance()

    const header = ref(null)
    const navbar = ref(null)
    const menu = [
      { id: 'home', name: t('menu.home'), href: '/', target: '', style: 'nav-link scrollto active' },
      { id: 'about', name: t('menu.about'), href: '/home/#about', target: '', style: 'nav-link scrollto' },
      { id: 'product', name: t('menu.product'), href: '/home/#product', target: '', style: 'nav-link scrollto' },
      { id: 'contact', name: t('menu.contact'), href: '/hom/#contact', target: '', style: 'nav-link scrollto' },
      { id: 'tmall', name: '天猫商店', href: 'http://tmall.com', target: '_blank', style: 'nav-link scrollto' },
      { id: 'jd', name: '京东商店', href: 'http://jd.com', target: '_blank', style: 'nav-link scrollto' }
    ]
    const route = useRoute()
    const router = useRouter()
    const path = computed(() => route.path)

    watch(() => router.currentRoute.value.path, (newValue, oldValue) => {
      // console.log(route.params.anchor)
      nextTick(() => {
        if (route.params.anchor) {
          const pos = route.params.anchor.indexOf('#')
          const dest = route.params.anchor.substring(pos)
          goToAnchor(dest)
        }
      })
    }, { immediate: true })

    function navClick (e) {
      const pos = e.indexOf('#')
      const dest = e.substring(pos)
      const url = e.substring(0, pos - 1)
      if (url === path.value) {
        goToAnchor(dest)
      } else {
        router.push({ name: 'homeView', params: { anchor: dest } })
      }
      // console.log(path.value)
    }

    function changeToChinese () {
      proxy.$i18n.locale = 'zh-cn'
      // this.$i18n.locale = 'zh-cn'
      // i18n.setLocale('zh-cn')
      console.log('change to chinese')
    }

    function changeToEnglish () {
      // i18n.setLocale('en-us')
      proxy.$i18n.locale = 'en-us'
      console.log('change to english')
    }

    function goToAnchor (dest) {
      dest = document.querySelector(dest)

      const headerEl = document.querySelector('#header')
      const offset = headerEl.offsetHeight

      // console.log('header offset', offset)
      const elementPos = dest.offsetTop
      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth'
      })
    }
    // 点击菜单，导航到相应#的位置（根据头部高度，以及组建位置，自动计算滚动到相应位置）
    /* function scrollEvent () {
      const scrolls = document.querySelectorAll('.scrollto')
      // console.log(scrolls)
      scrolls.forEach(function (s) {
        // console.log(s)
        s.addEventListener('click', function (e) {
          // console.log(s, e)
          if (s.hash) {
            e.preventDefault()
          }
          const headerEl = document.querySelector('#header')
          const offset = headerEl.offsetHeight

          // console.log('header offset', offset)
          const elementPos = document.querySelector(s.hash).offsetTop
          window.scrollTo({
            top: elementPos - offset,
            behavior: 'smooth'
          })
        })
      })
    } */
    // 返回顶端
    function toggleBacktotop () {
      const backtotop = document.querySelector('.back-to-top')
      if (backtotop) {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
    }

    /*     // 点击改变样式
    function changeCSS () {
      this.$refs.navbar.classList.toggle('navbar-mobile')
      this.$refs.menuButton.classList.toggle('bi-x')
      this.$refs.menuButton.classList.toggle('bi-list')
      console.log(this.$refs.navbar.classList)
      // this.$forceUpdate()
    } */

    // 菜单点击后关闭
    function closeMenu () {
      if (navbar.value.classList.contains('navbar-mobile')) {
        navbar.value.classList.remove('navbar-mobile')
        const navbarToggle = document.querySelector('.mobile-nav-toggle')
        navbarToggle.classList.toggle('bi-list')
        navbarToggle.classList.toggle('bi-x')
      }
    }
    // 菜单滚动
    function headerScrolled () {
      if (header.value) {
        if (window.scrollY > 100) {
          header.value.classList.add('header-scrolled')
        } else {
          header.value.classList.remove('header-scrolled')
        }
      }
    }

    // 页面滚动到某处，激活样式
    function navbarlinksActive () {
      const position = window.scrollY + 200
      const navbarlinks = document.querySelectorAll('#navbar .scrollto')
      navbarlinks.forEach(function (navbarlink) {
        if (!navbarlink.hash) return
        const section = document.querySelector(navbarlink.hash)
        if (!section) return
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          navbarlink.classList.add('active')
        } else {
          navbarlink.classList.remove('active')
        }
      })
    }

    function menuClick () {
      const el = document.querySelector('.mobile-nav-toggle')
      el.addEventListener('click', function () {
        document.querySelector('#navbar').classList.toggle('navbar-mobile')
        console.log(el, 'click')
        el.classList.toggle('bi-list')
        el.classList.toggle('bi-x')
      })
    }

    /*     // 如果地址栏带有#，刷新页面后，直接转到#位置
    function hashEvent () {
      if (window.location.hash) {
        if (document.querySelector(window.location.hash)) {
          window.scrollTo(window.location.hash)
        }
      }
    } */

    // region onMounted
    onMounted(() => {
      // hashEvent()
      // scrollEvent()
      // 返回顶端按钮

      document.addEventListener('scroll', toggleBacktotop)

      document.title = t('company.title')
      // 菜单滚动事件
      document.addEventListener('scroll', headerScrolled)

      document.addEventListener('scroll', navbarlinksActive)

      // 激活手机版菜单
      menuClick()
    })
    // endregion

    return { t, menu, header, navbar, closeMenu, navClick, changeToChinese, changeToEnglish }
  }

}
</script>
