module.exports = {
  menu: {
    home: 'Home',
    about: 'About',
    product: 'Products',
    contact: 'Contact',
    tmall: 'Tmall Store',
    JD: 'JD Store',
  },
  company: {
    title: 'RODDEX'
  },
  about:{
    aboutTitle:'About RODDEX',
    aboutContent1: 'RODDEX is one of the largest and the most famous sanitary ware companies in China. Headquartered in Shanghai, RODDEX’s areas of business include sanitary wares, wardrobes, and other customized refurbishment solution for private homes and hotels. Founded in 1975 as a valve factory, RODDEX, a subsidiary of Hong Sheng Group, was first known for its advanced lead-free faucet and kitchen and bath accessories. Out of 47-years’ market-leading bath technology and expertise, RODDEX now becomes one of the best-known Chinese sanitary companies integrating product design, manufacture and marketing.',
    aboutContent2: 'For the past 47 years, RODDEX played a key role in making Wenzhou, Zhejiang as one of the most important sanitary ware production and exportation bases in China, quickly gaining its position as one of the largest sanitary corporations in China. ',
    aboutContent3: 'RODDEX is not only a sanitary ware manufacturer, but also a innovative company systematically integrated sanitary industry chain with Research and Development, Manufacturing, Logistics, Marketing and Service. Inspired by the latest eco-friendly technology and intelligent technology, the company persists in innovation and exploration, bringing the frontier technology into real life and providing green and safe products to consumers, letting more and more people enjoy a healthy and comfortable life.'
  },
  product:{
    productTitle:'Products',
    productDescription:'Product Series',
    toilet:'Toilet',
    cabinet:'Cabinet',
    sink:'Sink',
    kitchenFaucet:'Kitchen Faucet',
    basinFaucet:'Basin Faucet',
    bathFaucet:'Bath Faucet',
    highBasinFaucet:'High Basin Faucet',
    showerFaucet: 'Shower Faucet',
    bathtub: 'Bathtub',
  },
  contact:{
    contactTitle:'Contact Us',
    addressLabel:'Address',
    address:'No. 258 Zhenying Road, Qingpu, Shanghai, China',
    emailLabel:'Email',
    email:'momo@roddex.net',
    phoneLabel:'Phone',

  },
  footer:{
    company: 'RODDEX',
  }
}
