module.exports = {
  menu: {
    home: '首页',
    about: '关于我们',
    product: '产品展示',
    contact: '联系我们',
    tmall: '天猫商城',
    JD: '京东商城',
  },
  company: {
    title: '劳达斯卫浴'
  },
  about:{
    aboutTitle:'关于劳达斯',
    aboutContent1:'从1975年创立，以生产阀门起家，到逐渐规模化生产龙头五金，成长为整体卫浴供应链。现已拥有近44年历史的鸿升集团劳达斯卫浴已成为\n' +
      '            国内拥有设计、生产、销售为一体的专业化知名卫浴品牌。',
    aboutContent2: '在过去四十多年，劳达斯以持之以恒的信念，逐步发展成为技术驱动和全生产链卫浴企业，并成为有规模的水龙头、卫浴五金及配套产品的现代化\n' +
      '            综合性大型生产及出口基地之一。\n' +
      '            公司自有品牌【RODDEX】劳达斯曾荣获国家免检产品、上海名牌产品、中国名牌产品和中国驰名商标等众多荣誉和认证，\n' +
      '            并且进驻了多加知名连锁建材市场，其产品更远销至欧美、亚太等五十多个国家和地区，获得了众多国际产品认证，信誉卓越。',
    aboutContent3: '劳达斯卫浴不仅仅是一个卫浴的生产企业，更是通过系统整合卫浴产业链，集研发、制造、物流、营销及服务为于一体的绿色创造型企业。\n' +
      '            劳达斯以创新探索的绿色智慧，不断将新的绿色环保技术和智能科技转换为实际应用，致力于为人们提供绿色安全地卫浴产品，\n' +
      '            让更多人享受到绿色卫浴带来的健康舒适生活。'
  },
  product:{
    productTitle:'产品介绍',
    productDescription:'全系列卫浴产品',
    toilet:'坐便器',
    cabinet:'浴室柜',
    sink:'水槽',
    kitchenFaucet:'厨房龙头',
    basinFaucet:'面盆龙头',
    bathFaucet:'浴缸龙头',
    highBasinFaucet:'高脚面盆龙头',
    showerFaucet: '淋浴柱',
    bathtub: '浴缸',
  },
  contact:{
    contactTitle:'联系方式',
    addressLabel:'地址',
    address:'上海市青浦工业园D区振盈路258号',
    emailLabel:'Email',
    email:'momo@roddex.net',
    phoneLabel:'电话',

  },
  footer:{
    company: '上海劳达斯卫浴有限公司 版权所有',
  }
}
