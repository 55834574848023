<template>
  <!-- ======= About Us Section ======= -->
  <section id="about" class="about ">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>{{$t('about.aboutTitle')}}</h2>
      </div>

      <div class="row content">

          <p>
            {{$t('about.aboutContent1')}}
          </p>
          <p>
            {{$t('about.aboutContent2')}}
          </p>
          <p>
            {{$t('about.aboutContent3')}}
          </p>

<!--        <div class="col-lg-6 pt-4 pt-lg-0">-->
<!--          <p>-->
<!--            Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate-->
<!--            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in-->
<!--            culpa qui officia deserunt mollit anim id est laborum.-->
<!--          </p>-->
<!--          <a href="#" class="btn-learn-more">Learn More</a>-->
<!--        </div>-->
      </div>

    </div>
  </section><!-- End About Us Section -->
</template>

<script>
export default {
  name: 'HsAbout'
}
</script>

<style scoped>

</style>
