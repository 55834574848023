<template>
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container" data-aos="fade-up">

      <div class="section-title">
        <h2>{{$t('contact.contactTitle')}}</h2>

      </div>

      <div class="row">

        <div class="col-lg-6 d-flex">
          <div class="info">
            <div class="address">
              <i class="bi bi-geo-alt"></i>
              <h4>{{$t('contact.addressLabel')}}</h4>
              <p>{{$t('contact.address')}}</p>
            </div>

            <div class="email">
              <i class="bi bi-envelope"></i>
              <h4>Email:</h4>
              <p>momo@roddex.net</p>
            </div>

            <div class="phone">
              <i class="bi bi-phone"></i>
              <h4>{{$t('contact.phoneLabel')}}</h4>
              <p>400 779 7779</p>
            </div>

          </div>

        </div>

        <div class="col-lg-6 d-flex">
          <div class="info">
            <img src="@/assets/img/contact_bg.jpg" class="img-fluid mx-auto d-block" alt="">
          </div>
        </div>

      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'HsContact'
}
</script>

<style scoped>

</style>
