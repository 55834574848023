<template>
  <div class="img-fluid">
    <carousel
      :wrap-around="true"
      :autoplay="3000"
      :items-to-show="1">
      <slide v-for="(slide,index) in slides" :key="index">
        <img class="d-block w-100" :src="slide" alt="">
      </slide>
      <template #addons>
      <navigation/>
      <pagination/>
      </template>
    </carousel>
  </div>

</template>

<script setup>
// import { onMounted } from 'vue'

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
const slides = [require('@/assets/img/banner1.jpg'), require('@/assets/img/banner2.jpg'), require('@/assets/img/banner3.jpg')]

// carl.carousel()
/* onMounted(() => {
  const carl = document.querySelector('.carousel')
  carl.carousel()
}) */
</script>
<style>

</style>
