<template>
  <HsNav/>

  <router-view>
  </router-view>
  <HsFooter/>
</template>

<script>
import AOS from './assets/vendor/aos/aos'

import { onMounted } from 'vue'
import HsNav from '@/components/HsNav'
import HsFooter from '@/components/HsFooter'

export default {
  name: 'App',
  components: { HsNav, HsFooter },
  setup () {
    onMounted(() => {
      AOS.init({
        duration: 1000,
        easing: 'ease-in-out',
        once: true,
        mirror: false
      })
    })
  }
}
</script>
